.logoImage {
  max-width: 70% !important;
}
.SideBarHeaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sideBarNavLink {
  text-decoration: none !important;
}

.sideBarNavLink span {
  color: #fff !important;
  opacity: 0.65;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
}

.sideBarNavLink img {
  width: 25px;
  object-fit: contain;
}
.sidebar-scroll{
  height: 100%;
  overflow-y: scroll;
}

.sidebar-scroll::-webkit-scrollbar {
  width: 5px;
}

.sidebar-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 254, 254);
}

.sidebar-scroll::-webkit-scrollbar-thumb {
  background: blue;
  border-radius: 10px;
}

