.Container {
  display: flex;
  flex-direction: column;
}

.inputBox {
  border: 1px solid #8c8c8c;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  padding: 0px 15px;
}

.noBorder {
  border: 0px solid #e5e5e5;
}

.inputtxt {
  color: var(--black-color);
  width: 100%;
  height: 60px;
  padding: 0px 15px;
  border: 1px solid #8c8c8c;
  border-radius: 10px;
  font-size: 16px;
}

.inputtxt::placeholder {
  color: #8c8c8c;
}

.inputPassContainer {
  display: flex;
  position: relative;
  background: var(--white-smoke-color);
  border-radius: 5px;
  border: 1px solid var(--light-gray);
}

.passwordIcon {
  position: absolute;
  right: 15px;
  top: 50%;
  bottom: 50%;
  transform: translateY(-50%);
  color: #707070;
  cursor: pointer;
}
