.schedule .schedule-btn-blue {
  background-color: #0b30a9;
}

.schedule .schedule-btn-pink {
  /* background-color: #FFD9DA; */
  background-color: #e28c8f;
  opacity: 0.6;
  color: white;
  font-size: 15px;
}

.schedule button {
  width: 165px;
  height: 60px;
  border-radius: 10px;
  /* opacity: 1; */
  color: white;
  margin-right: 10px;
  border: none;
  margin-top: 10px;
}

.schedule .schedule-btn-blue {
  background-color: #0b30a9;
}

.schedule .schedule-btn-pink {
  /* background-color: #FFD9DA; */
  background-color: #e28c8f;
  opacity: 0.6;
  color: white;
  font-size: 15px;
}

.modal-inp input {
  width: 100%;
  border: none;
  border-radius: 20px;
  border: none;
  outline: none;
  color: #6a6a6a;
}

.modal-inp {
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 46px;
  margin: 20px 0;
  padding: 10px;
}

.modal-inp.inp-gray input {
  background-color: #dcdcdc !important;
  border: none;
}

.modal-inp.inp-gray {
  background-color: #dcdcdc !important;
  border: none;
}

.modal-btn {
  width: 100%;
}

.modal-btn button {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 30px;
  padding: 15px 10px;
  background-color: #ef464b;
  color: white;
}
