.pagination {
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.pagination-button {
  background-color: #0b30a9 !important;
  color: white !important;
  padding: 10px 30px !important;
  border-radius: 6px !important;
}

.disabled-btn{
  background-color: rgba(9,19,66, 0.7) !important;
  color: white !important;

}