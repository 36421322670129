.doctor-detail-section1 {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.doctor-detail-imgBox {
  width: 130px;
  height: 130px;
  overflow: hidden;
  border-radius: 20px;
}

.doctor-detail-imgBox img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  overflow: hidden;
}

.doctordetail-icon {
  color: #ef464b;
  margin-right: 5px;
}

.doctor-detail-sectionss svg {
  float: left;
  margin-top: 5px;
}
.doctor-detail-sectionss span {
  display: flex;
}
.doctor-detail-sectionss {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.doctorDetail-fees-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 0px 4px #ef464b;
  height: 200px;
  border-radius: 13px;
  text-align: center;
}

.doctorDetail-fees-card h5 {
  margin: 0 !important;
}

.gray-color {
  color: #6a6a6a !important;
}

.doctorDetail-experience-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 4px #ef464b;
  height: 200px;
  border-radius: 13px;
}

.doctor-detail-tabs-card {
  box-shadow: 0px 1px 2px grey;
  border-radius: 8px;
}

.doctor-detail-tabs-header {
  padding: 1px;
}

.doctor-detail-tabs-header button {
  padding: 10px 0px;
  /* border-radius: 10px 10px 0px 0px;
    background-color: #0B30A9; */
  color: #dcdcdc;
  outline: none;
  border: none;
  width: 33.33%;
}

.ActiveTab {
  border-radius: 10px 10px 0px 0px;
  background-color: #0b30a9;
  color: white;
}

.doctor-detail-tabs-text p {
  padding: 20px;
  line-height: 2;
}

.MidLabBtn button {
  width: 367px;
  height: 60px;
  background-color: white;
  color: #0b30a9;
  font-size: 20px;
  /* box-shadow: 0px 10px 30px #EF464B33; */
  border: 1px solid #0b30a9;
  border-radius: 50px;
  opacity: 1;
  margin-top: 60px;
}

.MidLabBtn .active {
  background: #ef464b 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 30px #ef464b33;
  border-radius: 50px;
  opacity: 1;
  border: none;
  color: #ffffff;
  margin-left: 20px;
}
