@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace, Spartan-Bold;
}

@font-face {
  font-family: "Poppins Regular";
  src: local("Poppins Regular"),
    url(/static/media/Poppins-Regular.1c701c9a.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins Bold";
  src: local("Poppins Bold"),
    url(/static/media/Poppins-Bold.e2abc8f5.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Poppins Light";
  src: local("Poppins Light"),
    url(/static/media/Poppins-Light.be86c23c.ttf) format("truetype");
  font-weight: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Poppins-Regular;
  src: url(/static/media/Poppins-Regular.1c701c9a.ttf);
}

@font-face {
  font-family: Poppins-Medium;
  src: url(/static/media/Poppins-Medium.b59f6a63.ttf);
}

@font-face {
  font-family: Poppins-Bold;
  src: url(/static/media/Poppins-Bold.e2abc8f5.ttf);
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url(/static/media/Poppins-SemiBold.5981b450.ttf);
}

/*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  font-family: Poppins-Regular, sans-serif;
  overflow-x: hidden;
}

/*---------------------------------------------*/
a {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #a64bf4;
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

/* textarea:focus::-webkit-input-placeholder {
  color: transparent;
} */

textarea:focus:-moz-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #adadad;
}

input:-moz-placeholder {
  color: #adadad;
}

input::-moz-placeholder {
  color: #adadad;
}

input:-ms-input-placeholder {
  color: #adadad;
}

textarea::-webkit-input-placeholder {
  color: #adadad;
}

textarea:-moz-placeholder {
  color: #adadad;
}

textarea::-moz-placeholder {
  color: #adadad;
}

textarea:-ms-input-placeholder {
  color: #adadad;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

.txt1 {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.5;
  color: #666666;
}

.txt2 {
  font-family: Poppins-Regular;
  font-size: 14px;
  line-height: 1.5;
  color: #333333;
  text-transform: uppercase;
}

.bg1 {
  background-color: #3b5998;
}

.bg2 {
  background-color: #1da1f2;
}

.bg3 {
  background-color: #ea4335;
}

.btn-primary {
  background-color: transparent;
  border-color: transparent;
}

.btn-primary:hover {
  background-color: #19a831;
  border-color: transparent;
}

.table {
  margin-bottom: 0 !important;
}

.table thead th {
  border-left: 2px solid #dee2e6 !important;
  border-right: 2px solid #dee2e6 !important;
  padding: 10px;
}

.table td {
  border: 1px solid #dee2e6 !important;
  padding: 10px;
}

.table_recent {
  overflow-y: scroll !important;

  height: 75vh !important;
  width: 100% !important;
  padding-right: 0px;
  padding-left: 0px;
}
.evidence-table {
  height: 40vh !important;
}

/* width */
.table_recent::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.table_recent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
.table_recent::-webkit-scrollbar-thumb {
  background: #19a831;
  border-radius: 10px;
}

table tbody {
  overflow-x: scroll !important;
  width: 100%;
}
table::-webkit-scrollbar {
  width: 5px;
}

/* Track */
table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
table::-webkit-scrollbar-thumb {
  background: #19a831;
  border-radius: 10px;
}

/* Handle on hover */
/* .table_recent::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
} */

/* .btn-hover:hover{
  background-color: #6233cd;
} */

.place-add-btn-container {
  display: flex;
  justify-content: space-between;
  /* justify-content: flex-end; */
  /* margin-right: 3%; */
  margin-top: 1%;
}

body {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.video-card {
  margin: 20px;
  align-self: flex-start;
}

.videos-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.video__tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tagComponent {
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(187, 168, 168);
  padding: 5px 10px;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.tagComponent__tag {
  margin: 0;
  max-width: 100px;
  overflow: hidden;
}

.tagComponent__cancel {
  margin: 0;
  font-size: 10px;
  margin-left: 8px;
  cursor: pointer;
}

.new-big-modal {
  height: 600px;
  overflow-y: scroll;
}

.new-big-modal::-webkit-scrollbar {
  display: none;
}

.MuiListItemText-primary {
  color: #51087e !important;
  opacity: 1 !important;
  font-family: "Poppins Regular" !important;
}

.f-14 {
  font-size: 14px;
}

.radius-8 {
  border-radius: 8px !important;
}

.heading-color {
  color: #51087e !important;
}

div,
p,
span,
tr,
td,
input option {
  font-family: "Poppins Regular";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins Bold" !important;
}

select option:hover {
  background-color: #51087e !important;
}
.text-underline-none {
  text-decoration: none;
}
.MuiPaper-root::-webkit-scrollbar {
  width: 0px !important;
  display: none !important;
}

.circularLoader--container {
  align-self: center;
  display: grid;
  place-items: center;
  margin-top: 10px;
}

.c-p {
  cursor: pointer;
}

.t-t-c {
  text-transform: capitalize;
}

.fieldSetHeaderContainer {
  margin-top: 40px;
}

.crud-modal-image {
  width: 100%;
  object-fit: contain;
  height: 15vh;
  align-self: center;
}

.crud__ImagePicker {
  display: flex;
  flex-direction: column;
  width: 430px;
  margin: auto;
}

.MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root svg {
  color: #0b30a9 !important;
}

.supportInput {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 16px;
  width: 430px;
}

.admin_Input_cms {
  width: 100%;
  border: 1px solid rgb(172, 172, 172);
  border-radius: 10px;
  padding: 10px;
}

.admin_Input_cms:focus {
  border: 2px solid #ea4335;
}

/* body loader */
.body-loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImage {
  max-width: 70% !important;
}
.SideBarHeaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sideBarNavLink {
  text-decoration: none !important;
}

.sideBarNavLink span {
  color: #fff !important;
  opacity: 0.65;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;
}

.sideBarNavLink img {
  width: 25px;
  object-fit: contain;
}
.sidebar-scroll{
  height: 100%;
  overflow-y: scroll;
}

.sidebar-scroll::-webkit-scrollbar {
  width: 5px;
}

.sidebar-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 254, 254);
}

.sidebar-scroll::-webkit-scrollbar-thumb {
  background: blue;
  border-radius: 10px;
}


.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  /* display: -webkit-box; */
  /* display: -webkit-flex; */
  /* display: -moz-box; */
  /* display: -ms-flexbox; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-image: linear-gradient(to top, #ef464b, #0b30a9);
  /* background-color: #F3DFFF; */
}

.container-login100-form-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
}

.container-login100-form-btn-delete {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.wrap-login100 {
  width: 500px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 65px;
  padding-bottom: 54px;
  /* padding: 60px 30px; */
}

.wrap-login100-form-btn {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 25px;
  overflow: hidden;
  margin: 0 auto;

  box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
  -moz-box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
  -webkit-box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
  -o-box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
  -ms-box-shadow: 0 5px 30px 0px rgba(3, 216, 222, 0.2);
}

.wrap-login100-form-btn:hover .login100-form-bgbtn {
  left: 0;
}

.login100-form {
  width: 100%;
}

.login100-form-title {
  display: block;
  font-family: Poppins-Bold;
  font-size: 39px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 49px;
}

.login100-form-bgbtn {
  position: absolute;
  z-index: -1;
  width: 300%;
  height: 100%;
  background: #a64bf4;
  background: linear-gradient(right, #00dbde, #fc00ff, #00dbde, #fc00ff);
  top: 0;
  left: -100%;
  transition: all 0.4s;
}

.login100-form-btn {
  font-family: Poppins-Medium;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
}

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #d9d9d9;
}

.label-input100 {
  font-family: Poppins-Regular;
  font-size: 14px;
  color: #333333;
  line-height: 1.5;
  padding-left: 7px;
}

.input100 {
  font-family: Poppins-Medium;
  font-size: 16px;
  color: #333333;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 55px;
  background: transparent;
  padding: 0 7px 0 43px;
}

.textarea100 {
  font-family: Poppins-Medium;
  font-size: 16px;
  color: #333333;
  line-height: 1.2;

  display: block;
  width: 100%;
  min-height: 55px;
  max-height: 100px;
  background: transparent;
  padding: 0 7px 0 43px;
  margin-top: 15px;
}

.userNameContainer {
  margin-bottom: 23px;
}

.forgetBtn {
  padding-top: 8px;
  padding-bottom: 31px;
}

span.focus-input100 svg {
  fill: #adadad;
  margin-left: 5px;
}

.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  /* height: 100%; */
  top: 40px;
  left: 0px;
  padding-bottom: 8px;
  pointer-events: none;
}

.focus-input100::after {
  content: attr(data-symbol);
  font-family: Material-Design-Iconic-Font;
  color: #adadad;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: calc(100% - 20px);
  bottom: 0;
  left: 0;
  padding-left: 13px;
  padding-top: 3px;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 0;
  height: 2px;
  background: #a64bf4;
  transition: all 0.4s;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

.input100:focus + .focus-input100::after {
  color: #a64bf4;
}

.has-val.input100 + .focus-input100::after {
  color: #a64bf4;
}

.validate-input {
  position: relative;
}

@media (max-width: 992px) {
}

@media (max-width: 576px) {
  .wrap-login100 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.input-parentId {
  width: 200px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  height: 40px;
  padding: 0px 10px;
}
h1 {
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.input-parentId:focus {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.add-cat-btn {
  background-color: #044d81;
  color: white;
  border-radius: 10px;
  height: 40px;
  padding: 0px 30px;
}

.update-cat-btn {
  background-color: #19a831;
  color: white;
  border-radius: 10px;
  height: 40px;
  padding: 0px 30px;
}

.delete-cat-btn {
  background-color: #e60000;
  color: white;
  border-radius: 10px;
  height: 40px;
  padding: 0px 30px;
}

/* Category table class */
.category-icon-box {
  width: 90px;
  height: 90px;
  margin: auto;
}

.lab-working-days {
  list-style-type: number !important;
}

.categroy-modal .addlang-modalBody {
  /* / width: 200px; / */
  /* height: 368px;
      padding: 50px 50px; */
  /* / height: 256px; / */
  height: auto;
  padding: 10px 50px;
}

.categroy-modal .innerContainer {
  text-align: center;
}
.categroy-modal input {
  /* border-color: #dce0eb;
      width: 240px;
      height: 38px;
      padding: 2px 12px;
      border-radius: 8px;
      font-size: 15px;
      margin-top: 10px; */

  margin: 15px 0;
  padding: 13px 10px;
  width: 80%;
  outline: none;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}
.categroy-modal h2 {
  /* / margin-bottom: 28px; / */
  color: #fff;
  font-size: 28px;
  font-weight: 400;
}

.categroy-modal .btn-save {
  color: #fff;
  background-color: #044d81;
  padding: 12px 22px;
  /* / width: 299px; / */
  width: 148px;
  border-radius: 8px;
}
.categroy-modal .btn-cancel {
  color: #044d81;
  background-color: #eceff1;
  padding: 12px 22px;
  border: 1px solid #044d81;
  /* / width: 299px; / */
  width: 148px;
  border-radius: 8px;
}
.categroy-modal .btn-save:hover {
  color: white;
  background-color: #044d81;
}

.categroy-modal .btn-save:active {
  color: rgb(19, 19, 19);
  background-color: #abc7db;
}

.categroy-modal textarea {
  margin: 15px 0;
  padding: 13px 10px;
  width: 80%;
  outline: none;
  border: 1px solid #bbb;
  border-radius: 8px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s ease all;
}

.modal-Header {
  height: 90px;
  background-color: #044d81;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -1px;
  align-items: center;
  border-radius: 18px;
}

.categroy-modal .addlang-modalBody {
  height: auto;
  padding: 10px 50px;
}

.categroy-modal .innerContainer {
  text-align: center;
}

.categroy-modal .modal-content {
  border-radius: 20px !important;
}











.categroy-modal .icon-upload-avatar {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categroy-modal .icon-upload-avatar > input[type="file"] {
  position: absolute;
  top: -16px;
  width: 40%;
  height: 100%;
  opacity: 0;
}
.categroy-modal .icon-upload-avatar > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.categroy-modal .icon-upload-avatar > img {
  width: 40%;
}

.are-you-sure-label{
  color:#51087E  ;
}
.btn-color{
  background-color: #0b30a9;
}

.modal-scroll::-webkit-scrollbar{
  background-color: #ffffff !important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 7px;
}
.modal-scroll::-webkit-scrollbar-track{
  box-shadow: inset 0 0 5px white;
  background-color: #ffffff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px 0px;

  /* width: 10px; */
  /* border-radius: 6px; */
  /* color: red !important; */
}
.modal-scroll::-webkit-scrollbar-thumb{
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;

  background: #0b30a9 !important;
}
.font-14{
  font-size: 14px !important;
}
.font-17{
  font-size: 17px !important;
}
.pagination {
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.pagination-button {
  background-color: #0b30a9 !important;
  color: white !important;
  padding: 10px 30px !important;
  border-radius: 6px !important;
}

.disabled-btn{
  background-color: rgba(9,19,66, 0.7) !important;
  color: white !important;

}
.c-dashboardInfo {
  margin-bottom: 15px;
}
.c-dashboardInfo .wrap {
  background: #ffffff;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 40px 10px 20px;
  height: 200px;
}
.c-dashboardInfo__title,
.c-dashboardInfo__subInfo {
  color: #6c6c6c;
  font-size: 1.18em;
}
.c-dashboardInfo span {
  display: block;
}
.c-dashboardInfo__count {
  font-weight: 600;
  font-size: 2.5em;
  line-height: 64px;
  color: #323c43;
}
.c-dashboardInfo .wrap:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  content: "";
}
.c-dashboardInfo  .c-dashboardInfo__title {
  font-size: 16px;
}

/* .c-dashboardInfo:nth-child(1) .wrap:after {
  background: linear-gradient(82.59deg, #00c48c 0%, #00a173 100%);
} */
.c-dashboardInfo .wrap:after {
  background: linear-gradient(81.67deg, #0084f4 0%, #1a4da2 100%);
}
/* .c-dashboardInfo:nth-child(3) .wrap:after {
  background: linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%);
}
.c-dashboardInfo:nth-child(4) .wrap:after {
  background: linear-gradient(81.67deg, #ff647c 0%, #1f5dc5 100%);
}
.c-dashboardInfo:nth-child(5) .wrap:after {
  background: linear-gradient(81.67deg, #ff647c 0%, #1f5dc5 100%);
} */
.c-dashboardInfo__title svg {
  color: #d7d7d7;
  margin-left: 5px;
}
.MuiSvgIcon-root-19 {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  flex-shrink: 0;
}

.custom-btn {
  background-color: #0b30a9 !important;
  border: none !important;
  height: 42px;
  border-radius: 8px;
  font-size: 18px;
}
.update-btn {
  background-color: rgb(6, 175, 6) !important;
  border: none !important;
  height: 42px;
  border-radius: 8px;
  font-size: 16px;
}
.delete-btn {
  background-color: rgb(241, 37, 37) !important;
  border: none !important;
  height: 42px;
  border-radius: 8px;
  font-size: 16px;
}
.delete-btn:hover {
  background-color: rgb(189, 5, 5) !important;
  border: none !important;
}
.update-btn:hover {
  background-color: rgb(0, 136, 0) !important;
  border: none !important;
}

.custom-selection {
  outline: none;
  /* border: 2px solid #51087E; */
  border: 2px solid #0b30a9;
  border-radius: 8px;
  /* color: #0b30a9; */
  color: #51087e;
}

.no-data-table-row {
  border-bottom: 1px solid lightgray !important;
}
.no-data-table-content {
  min-height: 69vh;
  max-height: 69vh;
}
.no-data-table-content p {
  color: grey;
  font-weight: 500;
}

.appointmentUL {
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.appointmentUL > li {
  margin: 5px !important;
}

.word-break {
  word-break: break-all;
}

.custom-image-upload {
  width: 100%;
  height: 100%;
}

.section1-img-box {
  width: 500px;
  height: 400px;
}

.spinner-grow {
  color: #0b30a9;
  width: 5rem;
  height: 5rem;
}

.order-amount {
  width: 41%;
  text-align: right;
  padding: 20px 0px;
}

.payment-amount {
  padding: 10px 0px;
}

.paginationContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-block: 20px;
}

.add-evidence-modal-input{
    width: 100%;
    height: 60px;
    border: 1px solid #8C8C8C;
    border-radius: 10px;
    padding: 0px 15px;
    outline: none;
}

.add-evidence-modal-textarea{
    width: 100%;
    border: 1px solid #8C8C8C;
    border-radius: 10px;
    padding: 15px;
    min-height: 100px;
    max-height: 139px;
    outline: none;

}
.add-evidence-modal-label{
    color: black;
    font-weight: 600;
    font-size: 20px;
}
.add-evidence-modal-image-box{
    width: 98px;
    height: 97px;
    border: 1px solid #8C8C8C;
    border-radius: 10px;
}
.add-evidence-modal-image-box img{
    border-radius: 10px;

}
.add-evidence-modal-image-box-center-icon{
    width: 36px;
    height: 27px;
    border-radius: 0px !important;
    color:#8C8C8C;
}
.add-evidence-modal-addmore-btn{
    font-size: 22px;
    color: #0b30a9;
    font-weight: 500;
}

.chat-section {
  margin-top: 5%;
  margin-bottom: 25%;
}

.chat-section .chats-left-box {
  border-radius: 15px;
  max-height: 80vh;
  min-height: 80vh;
  box-shadow: 0px 20px 50px #0000001a;
}
.chat-section .chats-left-box .chat-header {
  height: 9vh;
  display: flex;
  align-items: center;
}
.chat-section .chats-left-box .chat-header h5 {
  padding-left: 5%;
  font-weight: bold;
}

.chat-section .chats-right-box {
  border-radius: 15px;
  height: 80vh;
  box-shadow: 0px 20px 50px #0000001a;
}

.chat-section .chats-right-box .chat-header {
  height: 10vh;
  box-shadow: 0px 2px 50px #8686861a;
  display: flex;
  align-items: center;
  padding: 0px 3%;
}

.chat-section .chats-right-box .chat-header h6 {
  margin-left: 1%;
  margin-bottom: 0px;
  width: 100%;
}
.chat-section .chats-right-box .chat-header span {
  color: #888888;
  font-size: 14px;
}

.chat-section .chats-right-box .chats-container {
  max-height: 60vh;
  min-height: 60vh;
  overflow-y: scroll;
  padding: 2% 0px;
}
.chat-section .chats-right-box .chats-container .container {
  max-width: 98%;
}
/* Empty Chhat List */
.chat-section .chats-left-box-messages .empty-chat-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.chat-section .chats-left-box-messages .empty-chat-list span {
  color: #505050;
}

/* Scrollbar */
.chat-section .chats-right-box .chats-container::-webkit-scrollbar,
.chat-section .chats-left-box-messages::-webkit-scrollbar {
  width: 6px;
}
.chat-section .chats-right-box .chats-container::-webkit-scrollbar-track {
  background-color: #ebebeb;
  border-radius: 10px 0px 0px 10px;
}
.chat-section .chats-right-box .chats-container::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 10px 0px 0px 10px;
}

/* Left scrollbar */
.chat-section .chats-left-box-messages {
  overflow-y: scroll;
  height: 71vh;
}
.chat-section .chats-left-box-messages::-webkit-scrollbar-track {
  background-color: #ebebeb;
  border-radius: 10px;
}
.chat-section .chats-left-box-messages::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 10px;
}

.chat-section .chats-right-box .chats-container .left-message {
  display: flex;
}
.chat-section .chats-right-box .chats-container .left-message .message {
  min-width: -webkit-min-content;
  min-width: min-content;
  max-width: -webkit-max-content;
  max-width: max-content;
  box-shadow: 0px 20px 50px #0000001a;
  height: -webkit-max-content;
  height: max-content;
  padding: 10px 20px;
  border-radius: 15px 15px 15px 0px;
  background-color: #28bad8;
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 0px;
  color: #ffffff;
  position: relative;
}
.chat-section .chats-right-box .chats-container .left-message .time {
  display: block;
  margin-left: 10px;
  color: #707070;
  margin-top: 5px;
}
/*  Arrow */
.chat-section .chats-right-box .chats-container .left-message .arrow::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  float: left;
  bottom: -6px;
  left: 0px;
  border: 6px solid;
  border-color: #28bad8 transparent transparent #28bad8;
}
.chat-section
  .chats-right-box
  .chats-container
  .right-message
  .message
  .arrow::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0px;
  bottom: -6px;
  right: 0px;
  border: 6px solid;
  border-color: #92d828 #92d828 transparent transparent;
}

.chat-section .chats-right-box .chats-container .right-message {
  display: flex;
  flex-direction: column;
  float: right;
  margin-bottom: 2%;
}

.chat-section .chats-right-box .chats-container .right-message .message {
  color: #ffffff;
  background-color: #92d828;
  min-width: -webkit-min-content;
  min-width: min-content;
  max-width: -webkit-max-content;
  max-width: max-content;
  box-shadow: 0px 20px 50px #0000001a;
  height: 100%;
  padding: 10px 20px;
  border-radius: 15px 15px 0px 15px;
  margin-bottom: 0px;
  position: relative;
}

.chat-section .chats-right-box .chats-container .right-message .time {
  color: #707070;
  float: right;
  font-size: 16px;
  margin-top: 5px;
}

/*  Chat List */
.chat-section .chats-left-box .chat-list {
  height: -webkit-max-content;
  height: max-content;
  cursor: pointer;
}
.chat-section .chats-left-box .chat-list:hover {
  background-color: rgba(25, 154, 180, 0.3);
}
.chat-section .chats-left-box .chat-list .container {
  border-bottom: 1px solid rgb(214, 214, 214);
  max-width: 90%;
  padding: 5% 0px;
}
.chat-section .chats-left-box .chat-list .username {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.chat-section .chats-left-box .chat-list .username h6 {
  margin-bottom: 0px;
}
.chat-section .chats-left-box .chat-list .username span {
  margin-top: 5px;
  font-size: 13px;
  color: #888888;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.chat-section .chats-left-box .chat-list .time-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.chat-section .chats-left-box .chat-list .time-box span {
  font-size: 14px;
  color: #888888;
}
.chat-section .chats-left-box .chat-list .time-box .remaining-msgs {
  margin-top: 5px;
  background-color: #92d828;
  color: #ffffff;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  text-align: center;
}
/* Chat Timeline */
.chat-section .chats-right-box .chat-timeline {
  margin-bottom: 3%;
}
.chat-section .chats-right-box .chat-timeline .left-border {
  border-bottom: 1px solid #505050;
  height: 100%;
}
.chat-section .chats-right-box .chat-timeline .right-border {
  border-bottom: 1px solid #505050;
  height: 100%;
}
.chat-section .chats-right-box .chat-timeline .time {
  color: #505050;
}

/*  Chat Footer */
.chat-section .chats-right-box .chat-footer {
  padding: 2vh 5%;
  height: 10vh;
  box-shadow: 0px -10px 50px #0000001a;
}
.chat-section .chats-right-box .chat-footer .message-input {
  width: 100%;
  outline: none;
  padding: 0px 20px;
  height: 6vh;
  border-radius: 50px;
  border: 1px solid rgb(214, 214, 214);
}
.chat-section .chats-right-box .chat-footer .message-input:focus {
  border: 1px solid #28bad8;
}
.chat-section .chats-right-box .chat-footer .send-btn {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: none;
  outline: none;
  background-color: #92d828;
}
.chat-section .chats-right-box .chat-footer .send-btn .icon {
  width: 30px;
  height: 30px;
  color: #ffffff;
}
.chat-section .chats-right-box .chat-footer .send-btn:hover {
  background-color: #199ab4;
  transition: all 0.2s linear;
}

/* Empty Box */
.chat-section .chats-right-box .center-empty-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.chat-section .chats-right-box .center-empty-icon h3 {
  color: #92d828;
}
.chat-section .chats-right-box .center-empty-icon img {
  width: 200px;
  height: 200px;
}

.schedule .schedule-btn-blue {
  background-color: #0b30a9;
}

.schedule .schedule-btn-pink {
  /* background-color: #FFD9DA; */
  background-color: #e28c8f;
  opacity: 0.6;
  color: white;
  font-size: 15px;
}

.schedule button {
  width: 165px;
  height: 60px;
  border-radius: 10px;
  /* opacity: 1; */
  color: white;
  margin-right: 10px;
  border: none;
  margin-top: 10px;
}

.schedule .schedule-btn-blue {
  background-color: #0b30a9;
}

.schedule .schedule-btn-pink {
  /* background-color: #FFD9DA; */
  background-color: #e28c8f;
  opacity: 0.6;
  color: white;
  font-size: 15px;
}

.modal-inp input {
  width: 100%;
  border: none;
  border-radius: 20px;
  border: none;
  outline: none;
  color: #6a6a6a;
}

.modal-inp {
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 46px;
  margin: 20px 0;
  padding: 10px;
}

.modal-inp.inp-gray input {
  background-color: #dcdcdc !important;
  border: none;
}

.modal-inp.inp-gray {
  background-color: #dcdcdc !important;
  border: none;
}

.modal-btn {
  width: 100%;
}

.modal-btn button {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 30px;
  padding: 15px 10px;
  background-color: #ef464b;
  color: white;
}

.doctor-detail-section1 {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.doctor-detail-imgBox {
  width: 130px;
  height: 130px;
  overflow: hidden;
  border-radius: 20px;
}

.doctor-detail-imgBox img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  overflow: hidden;
}

.doctordetail-icon {
  color: #ef464b;
  margin-right: 5px;
}

.doctor-detail-sectionss svg {
  float: left;
  margin-top: 5px;
}
.doctor-detail-sectionss span {
  display: flex;
}
.doctor-detail-sectionss {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.doctorDetail-fees-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 0px 4px #ef464b;
  height: 200px;
  border-radius: 13px;
  text-align: center;
}

.doctorDetail-fees-card h5 {
  margin: 0 !important;
}

.gray-color {
  color: #6a6a6a !important;
}

.doctorDetail-experience-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 4px #ef464b;
  height: 200px;
  border-radius: 13px;
}

.doctor-detail-tabs-card {
  box-shadow: 0px 1px 2px grey;
  border-radius: 8px;
}

.doctor-detail-tabs-header {
  padding: 1px;
}

.doctor-detail-tabs-header button {
  padding: 10px 0px;
  /* border-radius: 10px 10px 0px 0px;
    background-color: #0B30A9; */
  color: #dcdcdc;
  outline: none;
  border: none;
  width: 33.33%;
}

.ActiveTab {
  border-radius: 10px 10px 0px 0px;
  background-color: #0b30a9;
  color: white;
}

.doctor-detail-tabs-text p {
  padding: 20px;
  line-height: 2;
}

.MidLabBtn button {
  width: 367px;
  height: 60px;
  background-color: white;
  color: #0b30a9;
  font-size: 20px;
  /* box-shadow: 0px 10px 30px #EF464B33; */
  border: 1px solid #0b30a9;
  border-radius: 50px;
  opacity: 1;
  margin-top: 60px;
}

.MidLabBtn .active {
  background: #ef464b 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 30px #ef464b33;
  border-radius: 50px;
  opacity: 1;
  border: none;
  color: #ffffff;
  margin-left: 20px;
}

/* Please ❤ this if you like it! */
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.7;
  color: #1f2029;
  background-color: #fff;
  overflow: hidden;
  /* background-image: url("https://assets.codepen.io/1462889/back-page.svg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 101%;
}
p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  color: #1f2029;
}

.section {
  position: relative;
  width: 100%;
  display: block;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.full-height {
  min-height: 100vh;
}

/* [type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
} */
/* .modal-btn:checked + label,
.modal-btn:not(:checked) + label {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 2;
  height: 50px;
  transition: all 200ms linear;
  border-radius: 4px;
  width: 240px;
  letter-spacing: 1px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  -ms-flex-item-align: center;
  align-self: center;
  border: none;
  cursor: pointer;
  background-color: #102770;
  color: #ffeba7;
  box-shadow: 0 12px 35px 0 rgba(16, 39, 112, 0.25);
}
.modal-btn:not(:checked) + label:hover {
  background-color: #ffeba7;
  color: #102770;
}
.modal-btn:checked + label .uil,
.modal-btn:not(:checked) + label .uil {
  margin-left: 10px;
  font-size: 18px;
}
.modal-btn:checked + label:after,
.modal-btn:not(:checked) + label:after {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 110;
  width: 40px;
  border-radius: 3px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 18px;
  background-color: #ffeba7;
  color: #102770;
  font-family: "unicons";
  content: "\eac6";
  box-shadow: 0 12px 25px 0 rgba(16, 39, 112, 0.25);
  transition: all 200ms linear;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
}
.modal-btn:checked + label:hover:after,
.modal-btn:not(:checked) + label:hover:after {
  background-color: #102770;
  color: #ffeba7;
}
.modal-btn:checked + label:after {
  transition: opacity 300ms 300ms ease, transform 300ms 300ms ease,
    background-color 250ms linear, color 250ms linear;
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}
.modal {
  position: fixed;
  display: block;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow-x: hidden;
  background-color: rgba(31, 32, 41, 0.75);
  pointer-events: none;
  opacity: 0;
  transition: opacity 250ms 700ms ease;
}
.modal-btn:checked ~ .modal {
  pointer-events: auto;
  opacity: 1;
  transition: all 300ms ease-in-out;
}
.modal-wrap {
  position: relative;
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  overflow: hidden;
  padding-bottom: 20px;
  background-color: #fff;
  -ms-flex-item-align: center;
  align-self: center;
  box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
  opacity: 0;
  transform: scale(0.6);
  transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
} */
.modal-wrap img {
  display: block;
  width: 100%;
  height: auto;
}
.modal-wrap p {
  padding: 20px 30px 0 30px;
}
.modal-btn:checked ~ .modal .modal-wrap {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: opacity 250ms 500ms ease, -webkit-transform 350ms 500ms ease;
  transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
  transition: opacity 250ms 500ms ease, transform 350ms 500ms ease, -webkit-transform 350ms 500ms ease;
}

.logo {
  position: absolute;
  top: 25px;
  left: 25px;
  display: block;
  z-index: 1000;
  transition: all 250ms linear;
}
.logo img {
  height: 26px;
  width: auto;
  display: block;
  -webkit-filter: brightness(10%);
          filter: brightness(10%);
  transition: -webkit-filter 250ms 700ms linear;
  transition: filter 250ms 700ms linear;
  transition: filter 250ms 700ms linear, -webkit-filter 250ms 700ms linear;
}
.modal-btn:checked ~ .logo img {
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
  transition: all 250ms linear;
}

.pdf-input{
  color: transparent !important;
}
@media screen and (max-width: 500px) {
  .modal-wrap {
    width: calc(100% - 40px);
    padding-bottom: 15px;
  }
  .modal-wrap p {
    padding: 15px 20px 0 20px;
  }
}

/* .DoubleCalander {
} */

.datepicker_DoubleCalander__3Iq_X div[style]{
  display: none;

}
.datepicker_DoubleCalander__3Iq_X div div {
  border-right: 0px !important;
}
.input_Container__3Qu0C {
  display: flex;
  flex-direction: column;
}

.input_inputBox__P4heS {
  border: 1px solid #8c8c8c;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  padding: 0px 15px;
}

.input_noBorder__3Br9p {
  border: 0px solid #e5e5e5;
}

.input_inputtxt__1Ldw- {
  color: var(--black-color);
  width: 100%;
  height: 60px;
  padding: 0px 15px;
  border: 1px solid #8c8c8c;
  border-radius: 10px;
  font-size: 16px;
}

.input_inputtxt__1Ldw-::-webkit-input-placeholder {
  color: #8c8c8c;
}

.input_inputtxt__1Ldw-:-ms-input-placeholder {
  color: #8c8c8c;
}

.input_inputtxt__1Ldw-::placeholder {
  color: #8c8c8c;
}

.input_inputPassContainer__2WKsI {
  display: flex;
  position: relative;
  background: var(--white-smoke-color);
  border-radius: 5px;
  border: 1px solid var(--light-gray);
}

.input_passwordIcon__3tBEQ {
  position: absolute;
  right: 15px;
  top: 50%;
  bottom: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #707070;
  cursor: pointer;
}

