.input-parentId {
  width: 200px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  height: 40px;
  padding: 0px 10px;
}
h1 {
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.input-parentId:focus {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.add-cat-btn {
  background-color: #044d81;
  color: white;
  border-radius: 10px;
  height: 40px;
  padding: 0px 30px;
}

.update-cat-btn {
  background-color: #19a831;
  color: white;
  border-radius: 10px;
  height: 40px;
  padding: 0px 30px;
}

.delete-cat-btn {
  background-color: #e60000;
  color: white;
  border-radius: 10px;
  height: 40px;
  padding: 0px 30px;
}

/* Category table class */
.category-icon-box {
  width: 90px;
  height: 90px;
  margin: auto;
}

.lab-working-days {
  list-style-type: number !important;
}
