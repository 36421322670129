.custom-btn {
  background-color: #0b30a9 !important;
  border: none !important;
  height: 42px;
  border-radius: 8px;
  font-size: 18px;
}
.update-btn {
  background-color: rgb(6, 175, 6) !important;
  border: none !important;
  height: 42px;
  border-radius: 8px;
  font-size: 16px;
}
.delete-btn {
  background-color: rgb(241, 37, 37) !important;
  border: none !important;
  height: 42px;
  border-radius: 8px;
  font-size: 16px;
}
.delete-btn:hover {
  background-color: rgb(189, 5, 5) !important;
  border: none !important;
}
.update-btn:hover {
  background-color: rgb(0, 136, 0) !important;
  border: none !important;
}

.custom-selection {
  outline: none;
  /* border: 2px solid #51087E; */
  border: 2px solid #0b30a9;
  border-radius: 8px;
  /* color: #0b30a9; */
  color: #51087e;
}

.no-data-table-row {
  border-bottom: 1px solid lightgray !important;
}
.no-data-table-content {
  min-height: 69vh;
  max-height: 69vh;
}
.no-data-table-content p {
  color: grey;
  font-weight: 500;
}

.appointmentUL {
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.appointmentUL > li {
  margin: 5px !important;
}

.word-break {
  word-break: break-all;
}

.custom-image-upload {
  width: 100%;
  height: 100%;
}

.section1-img-box {
  width: 500px;
  height: 400px;
}

.spinner-grow {
  color: #0b30a9;
  width: 5rem;
  height: 5rem;
}

.order-amount {
  width: 41%;
  text-align: right;
  padding: 20px 0px;
}

.payment-amount {
  padding: 10px 0px;
}

.paginationContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-block: 20px;
}
